import Create from './create';
import Edit from './edit';
import List from './list';
import Show from './show';

export default {
    edit: Edit,
    create: Create,
    list: List,
    show: Show,
};
